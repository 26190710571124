<template>
  <b-card-code no-body title="List Category">
      <div class="dt_adv_search ml-1 mr-1">
        <div class="row">

            <div class="col-md-6">
                <b-button @click="cleanUpForm()" v-if="checkPermission('create commodity category')" v-b-modal.form-modal class="btn btn-info btn-sm mb-1" data-toggle="tooltip" data-placement="top" title="Add Category">
                    <feather-icon
                        icon="PlusCircleIcon"
                    />
                    Add Category
                </b-button>
            </div>

        </div>
    </div>
    <b-modal no-close-on-backdrop id="form-modal"  size="lg" :title="editid != null ? 'Edit Category' : 'Add Category'">
        <div class="form">

            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label for="name">Category Name:</label>
                        <input type="text" class="form-control" id="name" v-model="formPayload.name" placeholder="Category name...">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="parent_id">Parent: (Optional)</label>
                        <select class="form-control" v-model="formPayload.parent_id">
                        <option value="">-- Select Parent --</option>
                        <option v-for="parent in parents" :key="parent.slug" :value="parent.id">{{ parent.name }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row" v-if="formPayload.parent_id == '' || formPayload.parent_id == null">
                <div class="col-6">
                    <div class="form-group">
                    <label for="">Icon: (Optional)</label><br>
                    <input type="file" id="file" ref="file" @change="changeIcon()">
                    </div>
                </div>
                <div class="col-6">
                    <div v-if="formPayload.icon_url != null">
                    <small>Current icon:</small><br>
                    <img v-bind:src="formPayload.icon_url" style="max-width: 100%;">
                    </div>
                </div>
            </div>

            <div class="form-group">
              <label for="percentage_cash_balance_from_bank_guarantee">Percentage Deposit Cash from Bank Guarantee:</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="formPayload.percentage_cash_balance_from_bank_guarantee" placeholder="Percentage Deposit Cash from Bank Guarantee...">
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
            </div>

            <div class="form-group">
                <label for="description">Description: (Optional)</label>
                <textarea class="form-control" id="description" v-model="formPayload.description" placeholder="Category description..."></textarea>
            </div>


        </div>

        <template #modal-footer="{}">
            <b-button variant="success" @click="editid == null ? createItem() : updateItem()">
            Save Category
            </b-button>
        </template>
    </b-modal>
    <div class="table-responsive-sm">
        <table class="table b-table table-fixed">
            <thead>
            <tr>
                <th>Icon</th>
                <th>Category Name</th>
                <th>Parent Name</th>
                <th>Description</th>
                <th>Percentage Cash from BG</th>
                <th>Creation Time</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="isLoading">
                <td colspan="7" class="text-center">
                <b-spinner
                    class="mb-1"
                    variant="primary"
                /><br>
                Loading...
                </td>
            </tr>
            <tr v-else v-for="item in result.data" v-bind:key="item.id">
                <td v-if="item.icon_url != null"><img v-bind:src="item.icon_url" style="max-width: 50px;"></td><td v-else>-</td>
                <td>{{ item.name }}</td>
                <td>{{ item.parent != null ? item.parent.name : '-' }}</td>
                <td>{{ item.description != null ? item.description : '-' }}</td>
                <td>{{ item.percentage_cash_balance_from_bank_guarantee != null ? item.percentage_cash_balance_from_bank_guarantee + '%' : '-' }}</td>
                <td>{{ item.created_at }}</td>
                <td>
                    <div class="btn-group" role="group" aria-label="Basic example">
                    <button class="btn btn-info btn-sm" v-if="checkPermission('update commodity category')" @click="editItem(item)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Edit">
                        <feather-icon
                            icon="EditIcon"
                        />
                    </button>
                    <button class="btn btn-danger btn-sm" v-if="checkPermission('delete commodity category')" @click="deleteItem(item.id)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Delete">
                        <feather-icon
                            icon="Trash2Icon"
                        />
                    </button>
                </div>
                </td>
            </tr>
            <tr v-if="result.total == 0 && !isLoading">
                <td colspan="7" class="text-center">Data is empty.</td>
            </tr>
            </tbody>
        </table>

      <div class="m-1" v-if="result.total > 0">
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination :data="result" @pagination-change-page="getData" :limit="4" align="right"></pagination>
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Vue from 'vue';
import VuejsDialog from 'vuejs-dialog';
Vue.use(VuejsDialog);
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

export default {
  title () {
    return `Commodity Category Management`
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BSpinner
  },
  watch: {
    filter: {
      handler: _.debounce(function() { 
        this.getData();                                                                                                                                                                        
      }, 300),
      deep: true
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      isLoading: false,
      formPayload: Object,
      editid: null,
      parents: [],
    }
  },
  created() {
    this.getData();
    this.getParent();
  },
  methods: {
    cleanUpForm() {
      this.editid = null
      this.formPayload = {
        name: '',
        parent_id: '',
        description: '',
        icon: null,
        icon_url: null,
        percentage_cash_balance_from_bank_guarantee: null,
      };
    },
    getData(page = 1) {

      this.isLoading = true;
      this.currentPage = page;
      var queryParams = {}
      queryParams.page = this.currentPage

      this.$http.get('/admin/categories', {
        params: queryParams
      })
      .then(response => {
        this.result = response.data.data
        this.isLoading = false;
      })

    },
    getParent() {
        this.$http.get('/admin/categories', {
            params: {
                only_parent: 1,
                per_page: 1000
            }
        })
        .then(response => {
            this.parents = response.data.data.data
        })
    },
    changeIcon() {
      this.formPayload.icon = this.$refs.file.files[0];
    },
    createItem() {
      var form = this.preparePayload();

      this.$http.post('/admin/categories', form, {
        headers: { 'content-type': 'multipart/form-data' }
      })
      .then(response => {

        this.$bvModal.hide('form-modal')
        this.getData(this.currentPage)
        successNotification(this, 'Success', 'Category successfully created')
        this.cleanUpForm();

      })
      .catch(error => {
        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)  
        }
      })
    },
    updateItem() {
      var form = this.preparePayload();
      form.append('_method', 'PATCH');

      this.$http.post('/admin/categories/' + this.editid, form, {
        headers: { 'content-type': 'multipart/form-data' }
      })
      .then(response => {

        this.$bvModal.hide('form-modal')
        this.getData(this.currentPage)
        successNotification(this, 'Success', 'Category successfully updated!')
        this.cleanUpForm();

      })
      .catch(error => {
        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)          
        }
      })
    },
    preparePayload() {      
      var form = new FormData();
      for (var key in this.formPayload) {
          if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
            form.append(key, this.formPayload[key]);
          }
      }

      return form;
    },
    editItem(item) {
      this.editid = item.id

      this.formPayload = {
        name: item.name,
        parent_id: item.parent != null ? item.parent.id : null,
        description: item.description,
        icon: null,
        icon_url: item.icon_url,
        percentage_cash_balance_from_bank_guarantee: item.percentage_cash_balance_from_bank_guarantee,
      }

      this.$bvModal.show('form-modal')
    },
    deleteItem(id) {

      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this item?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          
          this.$http.delete('admin/categories/' + id)
          .then(response => {

            this.getData(this.currentPage)
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'Item successfully deleted',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
          .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)   
            }
          })

        }
      })

    },
  }
}
</script>